import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { FaqMeta } from "../data/seo-meta"

import caret from "../images/common/angle-down-sm_black.svg"
import clsx from "clsx"
import { Link } from "gatsby"

const CollapsableQuestion = ({ question, children }) => {
  const [expanded, setExpanded] = useState(false)
  const contentClass = clsx("p2 overflow-hidden", {
    "max-h-auto": expanded,
    "max-h-0": !expanded,
  })
  const caretClass = clsx("w-10 mt-1 mr-4 transition-all", {
    "rotate-0": expanded,
    "-rotate-90": !expanded,
  })

  const toggle = () => setExpanded((prevExpandedValue) => !prevExpandedValue)

  return (
    <article className="mb-8 md:mb-16">
      <h3
        className="p1 mb-2 font-semibold flex items-start cursor-pointer"
        onClick={toggle}
      >
        <img src={caret} className={caretClass} alt="toggle indicator" />
        {question}
      </h3>
      <p className={contentClass}>{children}</p>
    </article>
  )
}

CollapsableQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const FaqPage = () => {
  return (
    <Layout>
      <SEO
        path={FaqMeta.path}
        description={FaqMeta.description}
        title={FaqMeta.title}
      />
      <div className="container py-20 md:py-24">
        <h1 className="h1 mb-10 md:mb-20">FAQs</h1>

        <section>
          <h2 className="h2 mb-8">General</h2>
          <CollapsableQuestion question="What does Slice do?">
            Slice offers smart and user-friendly on-demand digital insurance solutions tailored for small businesses. 
            Through their cloud-based platform, Slice provides small business insurance products.
          </CollapsableQuestion>
          <CollapsableQuestion question="Who is Slice?">
            Slice has been shaping the future of insurance since 2015, and is backed by leading global insurers and 
            reinsurers. Headquartered in New York City, Slice has been consistently recognized as an insurance and 
            technology leader by industry publications, awards, and Tier 1 publications. To stay up to date, connect 
            with Slice on&nbsp;
            <a
              className="underline"
              href="https://www.linkedin.com/company/slice-labs-inc-/"
              rel="nofollow noreferrer"
              target="_blank"
            >
              LinkedIn
            </a>
            .
          </CollapsableQuestion>
          <CollapsableQuestion question="How do I contact Slice?">
            Please visit&nbsp;
            <Link to="/contact/" className="underline">
              https://sliceinsurance.com/contact/
            </Link>
            &nbsp;for contact options.
          </CollapsableQuestion>
        </section>

        <hr className="my-20" />

        <section>
          <h2 className="h2 mb-8">Products</h2>
          <CollapsableQuestion question="What products do you offer?">
            We currently offer the following on-demand small business insurance
            products:
            <br />
            <br />
            <ul className="list-disc">
              <li className="ml-14">
                Contractors General Liability
              </li>
              <li className="ml-14">
                Workers’ Compensation
              </li>
            </ul>
          </CollapsableQuestion>
          <CollapsableQuestion question="Will you offer additional small business insurance products?">
            Yes, we are always forming new partnerships and building our product
            suite for small businesses.
          </CollapsableQuestion>
          <CollapsableQuestion question="Do you offer homeshare insurance in the US?">
            We no longer offer homeshare insurance in the US.
          </CollapsableQuestion>
          <CollapsableQuestion question="How do I get help with a policy or claim?">
            Please contact your insurance carrier via information on your
            policy. Or you may reach us by phone on our toll‑free line at&nbsp;
            <a
              className="underline"
              href="tel:18556384465"
              rel="nofollow noreferrer"
              target="_blank"
            >
              1‑855‑638‑4465
            </a>
            &nbsp;or email at&nbsp;
            <a className="underline" href="mailto:helpme@slice.is">
              helpme@slice.is
            </a>
            .
          </CollapsableQuestion>
        </section>

        <hr className="my-20" />

        <section>
          <h2 className="h2 mb-8">Charges / Payments</h2>
          <CollapsableQuestion question="I have a charge on my credit card from (855-638-4465, or Bus.Insur.855-959-1844). Is that Slice?">
            This is a charge for your small business insurance policy. Please
            refer to your policy documents sent via email or call the phone
            number next to the charge on your credit card statement.
          </CollapsableQuestion>
          <CollapsableQuestion question="Who do I speak to about payments or policy renewals?">
            Please contact your insurance agent or reach us by phone on our
            toll‑free line at&nbsp;
            <a
              className="underline"
              href="tel:18556384465"
              rel="nofollow noreferrer"
              target="_blank"
            >
              1‑855‑638‑4465
            </a>
            &nbsp;or email at&nbsp;
            <a
              className="underline"
              href="mailto:helpme@slice.is"
              rel="nofollow noreferrer"
              target="_blank"
            >
              helpme@slice.is
            </a>
            .
          </CollapsableQuestion>
        </section>
      </div>
    </Layout>
  )
}

export default FaqPage
